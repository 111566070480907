.timelineActiveItem,
.timelineItem:hover {
	transform: scale(1.09);
	font-weight: 400;
	font-size: 24px;
	position: relative;
	transition: all 0.2s ease-in-out;
	color: #000;
}
.timelineActiveItem::before {
	content: "";
	position: absolute;
	left: -14px;
	top: 0;
	height: 100%;
	background: linear-gradient(180deg, #ff9e66 0%, #ec6e26 100%);
	width: 6px;
	border-radius: 5px;
}
timelineslideFetch > div > div > div {
	display: flex !important;
}
.timeline-slide-wrapper {
	height: 600px !important;
}

/* h1,
h2,
h3 {
	font-style: normal;
}

h1 {
	font-size: 36px;

	line-height: 1.2; */
/* text-align: center; */
/* }

h2 {
	margin: 50px 0 10px;
}

p {
	font-size: 18px;
} */

circle {
	stroke-dashoffset: 0;
	stroke-width: 5%;
	fill: none;
}

.bg {
	stroke: var(--accent);
	opacity: 0.2;
}

.progress .indicator {
	stroke: "red" !important;
}

.midContainer {
	padding: 0 50px;
	position: sticky;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	top: 0px;
	height: 100vh;
	width: 100%;
	overflow: hidden;
}

.sec {
	padding: 0 50px;
	position: sticky;
	display: flex;
	flex-direction: row;
	-webkit-box-align: center;
	align-items: center;
	top: 0px;
	row-gap: 20px;
	width: 100%;
	height: 100vh;
}

.sub {
	height: 100vh;
	background-color: white;
	width: 100%;
	display: flex;
}

.left {
	width: 10%;
	height: 70%;
	line-height: 1.6;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.left-active {
	/* width: 20%; */
	color: #000;
	border-left: solid 4px #ff5032;
	border-radius: 4px;
}

@media (min-width: 600px) {
	.timeline-slide-wrapper {
		height: 650px !important;
	}
}
@media (min-width: 768px) {
	.timeline-slide-wrapper {
		height: 750px !important;
	}
}
