/* ================================================= FUNDAMENTAL LAYOUT =================================================   */
.fundamental-row {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 160px;
}

@media (max-width: 991px) {
  .fundamental-row {
    flex-direction: column;
    padding-top: 80px;
  }
}

.fundamental-row .fundamental-image {
  width: 100%;
  max-width: 450px;
  height: auto;
}

.fundamental-row .fundamental-image img {
  width: 100%;
  height: auto;
}

.fundamental-row .fundamental-content .img-fluid {
  width: 100%;
  height: auto;
}

@media (max-width: 1200px) {
  .fundamental-row .fundamental-image {
    max-width: 400px;
  }
}

@media (max-width: 1024px) {
  .fundamental-row .fundamental-image {
    max-width: 300px;
  }
}

@media (max-width: 991px) {
  .fundamental-row .fundamental-image {
    max-width: 500px;
  }
}

@media (max-width: 991px) {
  .fundamental-row .fundamental-content .img-fluid {
    padding: 0 36px;
  }
}

.fundamental-row .fundamental-content {
  width: calc(100% - 590px);
}

@media (max-width: 1200px) {
  .fundamental-row .fundamental-content {
    width: calc(100% - 500px);
  }
}

@media (max-width: 1024px) {
  .fundamental-row .fundamental-content {
    width: calc(100% - 380px);
  }
}

@media (max-width: 991px) {
  .fundamental-row .fundamental-content {
    width: 100%;
    max-width: 500px;
    margin-bottom: 50px;
  }
}

.fundamental-title {
  font-size: 72px;
  font-weight: 300;
  line-height: 90px;
  margin-bottom: 40px;
}

@media (max-width: 1280px) {
  .fundamental-title {
    font-size: 60px;
    line-height: 72px;
    margin-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .fundamental-title {
    font-size: 48px;
    line-height: 54px;
    margin-bottom: 16px;
    text-align: center;
  }
}

@media (max-width: 475px) {
  .fundamental-title {
    font-size: 32px;
    line-height: 40px;
  }
}

@media (max-width: 340px) {
  .fundamental-title {
    font-size: 30px;
    line-height: 36px;
  }
}

.fundamental-big-image {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 140px;
  margin-top: 160px;
}

@media (max-width: 1406px) {
  .big-image-content {
    padding: 0 20px;
  }
}

@media (max-width: 1200px) {
  .fundamental-big-image {
    gap: 100px;
  }
}

@media (max-width: 1024px) {
  .fundamental-big-image {
    gap: 80px;
  }
}

@media (max-width: 991px) {
  .fundamental-big-image {
    flex-direction: column-reverse;
    margin-top: 50px;
    gap: 50px;
  }
}

@media (min-width: 1512px) and (max-width: 1920px) {
  .fundamental-big-image {
    gap: 110px;
  }
}

.big-image-container {
  width: 100%;
  height: auto;
  max-width: calc(
    calc(calc(100vw - var(--japfa-grid)) / 2) + calc(var(--japfa-grid) - 590px)
  );
}

@media (max-width: 1200px) {
  .big-image-container {
    max-width: calc(
      calc(calc(100vw - var(--japfa-grid)) / 2) +
        calc(var(--japfa-grid) - 500px)
    );
  }
}

@media (max-width: 1024px) {
  .big-image-container {
    max-width: calc(100% - 300px);
  }
}

@media (max-width: 991px) {
  .big-image-container {
    max-width: 500px;
  }
}

.big-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.big-image-content {
  width: 100%;
  height: auto;
  max-width: 450px;
}

@media (max-width: 1200px) {
  .big-image-content {
    max-width: 400px;
  }
}

@media (max-width: 1024px) {
  .big-image-content {
    max-width: 300px;
  }
}

@media (max-width: 991px) {
  .big-image-content {
    max-width: 500px;
  }
}

.big-image-content .big-image-title {
  font-size: 40px;
  font-weight: 800;
  line-height: normal;
}

@media (min-width: 1512px) and (max-width: 1920px) {
  .big-image-content .big-image-title {
    width: 490px;
    font-size: 36px;
  }
}

@media (max-width: 575px) {
  .big-image-content .big-image-title {
    font-size: 32px;
    font-weight: 800;
    line-height: 40px;
  }
}

.big-image-content .big-image-details {
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
}

@media (min-width: 1512px) and (max-width: 1920px) {
  .big-image-content .big-image-details {
    width: 430px;
  }
}

@media (max-width: 575px) {
  .big-image-content .big-image-details {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    width: 330px;
  }
}

.big-image-content hr {
  margin: 60px 0 30px;
}

@media (max-width: 575px) {
  .big-image-content hr {
    margin: 40px 0 16px;
  }
}

.big-image-title hr {
  margin: 40px 0 12px;
  width: 120px;
  color: #000000;
}

@media (max-width: 575px) {
  .big-image-title hr {
    width: 63%;
  }
}
