.pdf-tile {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 300px;
	height: 200px;
	background-color: #f7e5dd;
	cursor: pointer;
	margin: 10px;
}

.pdf-tile p {
	margin-top: 10px;
	text-align: center;
}

.pdf-tile i {
	font-size: 40px;
	color: #333;
}
.PdfTileLayout {
	display: flex;
	align-items: center;
	justify-content: center;
}
