.button-container {
	display: flex;
	flex-wrap: wrap;
	gap: 25px;
	margin: 10px;
}

.button {
	background-color: #fff;
	border: none;
	display: flex;
	align-items: center;
	gap: 8px;
	border-radius: 4px;
	padding: 4px 12px 4px 4px;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
}

.button.active {
	background: linear-gradient(180deg, #ffebe0 0%, #ffd0b6 100%);
}
