/* ================================================= SUSTAINABILITY LAYOUT =================================================   */
.sustainability-top-padding {
	padding: 150px 0px 40px;
}
@media (max-width: 1368px) {
	.sustainability-top-padding {
		padding: 150px 0px 40px;
	}
}

/* @media (max-width: 575px) {
	.sustainability-top-padding {
		padding-top: 120px;
		padding-bottom: 80px;
	}
} */

.rays-title {
	width: 100%;
	height: auto;
	text-align: center;
	font-size: 40px;
	font-weight: 800;
	line-height: normal;
	margin-bottom: 40px;
}

@media (max-width: 1280px) {
	.rays-title {
		font-size: 30px;
	}
}

@media (max-width: 575px) {
	.rays-title {
		font-size: 24px;
		font-weight: 800;
		margin-bottom: 24px;
	}
}

.rays-title span {
	position: relative;
}

.rays-title span::before {
	content: "";
	position: absolute;
	top: -20px;
	left: -25px;
	width: 36px;
	height: 36px;
	background-image: url("../../../public/assets/img/rays.svg");
	background-repeat: no-repeat;
	background-position: center center;
}

.rays-sub-title {
	width: 100%;
	max-width: 1368px;
	height: auto;
	text-align: center;
	font-size: 32px;
	font-weight: 400;
	line-height: 56px;
}

@media (min-width: 768px) and (max-width: 1280px) {
	.rays-sub-title {
		font-size: 32px;
	}
}
@media (max-width: 768px) {
	.rays-sub-title {
		font-size: 24px;
		line-height: 36px;
		font-weight: 400;
		/* padding: 0 24px; */
		max-width: 420px;
		margin: 0 auto;
	}
}

.planet-one-img {
	width: 100%;
	height: auto;
	margin: 100px 0;
	display: flex;
	justify-content: center;
}

.planet-one-img img {
	max-width: 800px;
}

@media (max-width: 1280px) {
	.planet-one-img img {
		max-width: 100%;
		padding: 0 24px;
	}

	.planet-one-img {
		margin: 80px 0;
	}
}

@media (max-width: 575px) {
	.planet-one-img {
		margin: 60px 0;
	}
}

.we-care {
	width: 100%;
	height: auto;
	margin-bottom: 100px;
}

@media (max-width: 1280px) {
	.we-care {
		margin-bottom: 80px;
	}
}

@media (max-width: 575px) {
	.we-care {
		margin-bottom: 40px;
	}
}

.we-care .we-care-title {
	text-align: center;
	font-size: 36px;
	font-weight: 400;
	line-height: 42px;
}

@media (max-width: 1280px) {
	.we-care .we-care-title {
		font-size: 30px;
		line-height: 36px;
	}
}

@media (max-width: 575px) {
	.we-care .we-care-title {
		font-size: 24px;
		line-height: 30px;
	}
}

.we-care ul {
	margin: 30px 0 0;
	padding: 0;
	text-align: center;
}

.we-care ul li {
	display: inline-block;
	background-color: #fff9ef;
	padding: 15px 20px;
	font-size: 22px;
	font-weight: 400;
	line-height: 26px;
	margin: 12px;
	border-radius: 12px;
}

@media (max-width: 1280px) {
	.we-care ul li {
		padding: 10px 20px;
		font-size: 20px;
		line-height: 24px;
	}
}

@media (max-width: 575px) {
	.we-care ul li {
		font-size: 16px;
		line-height: 20px;
		margin: 8px;
	}
}

.bg-maroon {
	background-color: #6a0600;
}

.box-layout-container {
	width: 100%;
	height: auto;
}

.box-layout-container .box-layout-row {
	width: 100%;
	height: auto;
	display: flex;
	align-items: stretch;
}

@media (max-width: 991px) {
	.box-layout-container .box-layout-row {
		flex-direction: column;
		height: 100%;
	}
}

.box-layout-container .box-layout-row .box-layout-col {
	width: 100%;
	height: auto;
	display: flex;
	align-items: center;
	background-color: var(--japfa-secondary);
}

@media (max-width: 575px) {
	.box-layout-container .box-layout-row .box-layout-col {
		flex-direction: column;
	}
}

.box-layout-col.bg-maroon {
	background-color: #6a0600 !important;
}

.box-layout-col.box-col-1,
.box-layout-col.box-col-4 {
	background-color: #6a0600 !important;
}

.box-layout-col.box-col-2,
.box-layout-col.box-col-3 {
	flex-direction: row-reverse;
}

@media (max-width: 991px) {
	.box-layout-col.box-col-1,
	.box-layout-col.box-col-3 {
		background-color: #6a0600 !important;
	}

	.box-layout-col.box-col-2,
	.box-layout-col.box-col-4 {
		background-color: var(--japfa-secondary) !important;
	}

	.box-layout-col.box-col-3 {
		flex-direction: unset;
	}

	.box-layout-col.box-col-4 {
		flex-direction: row-reverse;
	}
}

.box-layout-container .box-layout-row .box-layout-col.layout-reverse {
	flex-direction: row-reverse;
}

@media (max-width: 575px) {
	.box-layout-container .box-layout-row .box-layout-col.layout-reverse {
		flex-direction: column;
	}
}

.box-layout-container .box-layout-row .box-layout-col .box-img {
	width: 100%;
	max-width: 342px;
	height: 362px;
	overflow: hidden;
}

.box-layout-container .box-layout-row .box-layout-col .box-img img {
	object-fit: cover;
	width: 100%;
	height: 100%;
	object-position: center;
}

.box-layout-container .box-layout-row .box-layout-col .box-content {
	/* width: calc(100% - 342px); */
	height: 100%;
	padding: 0 60px;
	color: var(--japfa-white);
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	gap: 12px;
}

@media (max-width: 1660px) {
	.box-layout-container .box-layout-row .box-layout-col .box-img {
		max-width: 240px;
		height: 362px;
	}

	.box-layout-container .box-layout-row .box-layout-col .box-content {
		/* width: calc(100% - 255px); */
	}
}

@media (max-width: 1440px) {
	.box-layout-container .box-layout-row .box-layout-col .box-img {
		max-width: 260px;
		height: 270px;
	}

	.box-layout-container .box-layout-row .box-layout-col .box-content {
		/* width: calc(100% - 260px); */
	}
}

@media (max-width: 1366px) {
	.box-layout-container .box-layout-row .box-layout-col .box-content {
		padding: 30px;
	}
}

@media (max-width: 575px) {
	.box-layout-container .box-layout-row .box-layout-col .box-content {
		padding: 24px;
	}
}

@media (max-width: 1280px) {
	.box-layout-container .box-layout-row .box-layout-col .box-img {
		max-width: 240px;
		height: 340px;
	}

	.box-layout-container .box-layout-row .box-layout-col .box-content {
		/* width: calc(100% - 240px); */
	}
}

@media (max-width: 1100px) {
	.box-layout-container .box-layout-row .box-layout-col .box-img {
		max-width: 200px;
		height: 280px;
	}

	.box-layout-container .box-layout-row .box-layout-col .box-content {
		/* width: calc(100% - 200px); */
	}
}

@media (max-width: 991px) {
	.box-layout-container .box-layout-row .box-layout-col .box-img {
		max-width: 342px;
		height: 362px;
	}

	.box-layout-container .box-layout-row .box-layout-col .box-content {
		/* width: calc(100% - 342px); */
	}
}

@media (max-width: 768px) {
	.box-layout-container .box-layout-row .box-layout-col .box-img {
		max-width: 240px;
		height: 340px;
	}

	.box-layout-container .box-layout-row .box-layout-col .box-content {
		/* width: calc(100% - 240px); */
	}
}

@media (max-width: 575px) {
	.box-layout-container .box-layout-row .box-layout-col .box-img {
		max-width: 100%;
		height: auto;
		aspect-ratio: 1/0.75;
	}

	.box-layout-container .box-layout-row .box-layout-col .box-content {
		/* width: 100%; */
	}
}

.box-layout-container .box-layout-title {
	font-size: 40px;
	font-weight: 400;
	line-height: 56px;
}

.box-layout-container .box-layout-desc {
	font-size: 20px;
	font-weight: 400;
	line-height: 32px;
}

@media (max-width: 1366px) {
	.box-layout-container .box-layout-title {
		font-size: 24px;
		line-height: 36px;
	}

	.box-layout-container .box-layout-desc {
		font-size: 14px;
		line-height: 18px;
		font-weight: 400;
	}
}

@media (min-width: 400px) and (max-width: 1919px) {
	.box-layout-container .box-layout-desc {
		width: 374px;
	}
}

@media (min-width: 1920px) {
	.box-layout-container .box-layout-desc {
		width: 460px;
	}
}

.japfa-neeti-row {
	width: 100%;
	height: auto;
	display: flex;
	align-items: flex-start;
	gap: 170px;
	margin-top: 120px;
}

@media (max-width: 991px) {
	.japfa-neeti-row {
		gap: 100px;
		margin-top: 80px;
		flex-direction: column;
	}
}

@media (max-width: 768px) {
	.japfa-neeti-row {
		gap: 20px;
		margin-top: 60px;
		flex-direction: column;
		/* padding: 0 24px; */
	}
}

.japfa-neeti-row .japfa-neeti-col {
	width: 100%;
	max-width: 630px;
	height: auto;
}

@media (max-width: 991px) {
	.japfa-neeti-row .japfa-neeti-col {
		max-width: 100%;
	}
}

.japfa-neeti-row .japfa-neeti-col:last-child {
	width: 100%;
	height: auto;
	padding-left: 30px;
}

@media (max-width: 991px) {
	.japfa-neeti-row .japfa-neeti-col:last-child {
		padding-left: 0;
	}
}

.japfa-neeti-col .japfa-neeti-title {
	font-size: 72px;
	font-weight: 300;
	line-height: 90px;
	font-style: normal;
}

@media (max-width: 1366px) {
	.japfa-neeti-col .japfa-neeti-title {
		font-size: 48px;
		line-height: 54px;
	}
}

@media (max-width: 768px) {
	.japfa-neeti-col .japfa-neeti-title {
		font-size: 24px;
		line-height: 36px;
		font-weight: 400;
		width: 300px;
	}
}

.japfa-neeti-col .japfa-neeti-content {
	position: relative;
}

@media (min-width: 475px) {
	.japfa-neeti-col .japfa-neeti-content {
		/* width: 542px; */
	}
}

@media (max-width: 1366px) {
	.japfa-neeti-col .japfa-neeti-content {
		font-size: 14px;
	}
}

.japfa-neeti-col .japfa-neeti-content::before {
	content: "";
	position: absolute;
	top: -12px;
	left: 0;
	z-index: 1;
	width: 100px;
	height: 1px;
	background-color: rgba(0, 0, 0, 1);
}

.japfa-neeti-col .japfa-neeti-content p {
	font-size: 20px;
	font-weight: 400;
	line-height: 32px;
	margin-bottom: 24px;
}

@media (max-width: 1366px) {
	.japfa-neeti-col .japfa-neeti-content p {
		font-size: 14px;
		line-height: 18px;
	}
}

@media (max-width: 575px) {
	.japfa-neeti-col .japfa-neeti-content p {
		/* width: 342px; */
	}
}

.japfa-neeti-img-content {
	width: 100%;
	height: auto;
	display: flex;
	align-items: center;
	margin-top: 80px;
}

@media (max-width: 575px) {
	.japfa-neeti-img-content {
		margin-top: 60px;
	}
}

@media (max-width: 991px) {
	.japfa-neeti-img-content {
		flex-direction: column;
	}
}

.japfa-neeti-img-content .japfa-neeti-img {
	width: 50%;
	margin-left: 5px;
	position: relative;
}

@media (max-width: 1366px) {
	.japfa-neeti-img-content .japfa-neeti-img {
		height: 500px;
		margin-left: 100px;
	}
}

@media (max-width: 991px) {
	.japfa-neeti-img-content .japfa-neeti-img {
		width: calc(100% - 100px);
		height: auto;
		margin-left: 100px;
	}
}

@media (max-width: 768px) {
	.japfa-neeti-img-content .japfa-neeti-img {
		width: 100%;
		height: auto;
		margin-left: 0px;
	}
}

.japfa-neeti-img-content .japfa-neeti-img img {
	width: 100%;
	object-fit: cover;
	object-position: center;
}

@media (max-width: 1366px) {
	.japfa-neeti-img-content .japfa-neeti-img img {
		height: 100%;
	}
}

/* .japfa-neeti-img-content .japfa-neeti-img::before {
  content: "";
  width: 140px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -140px;
  z-index: 1;
  background: var(
    --Gradient-1,
    linear-gradient(315deg, #ff2525 -0.01%, #ff8743 100%)
  );
} */

@media (max-width: 1366px) {
	.japfa-neeti-img-content .japfa-neeti-img::before {
		width: 100px;
		left: -100px;
	}
}

.japfa-neeti-left {
	width: 670px;
	height: auto;
	margin-left: calc(calc(calc(100% - var(--japfa-grid)) / 2) + 20px);
	position: relative;
	z-index: 10;
}

@media (max-width: 1366px) {
	.japfa-neeti-left {
		width: 50%;
		padding-left: 20px;
		margin-left: 0;
	}
}

@media (max-width: 991px) {
	.japfa-neeti-left {
		width: 100%;
		padding: 0 20px;
		margin-left: 0;
		text-align: center;
		margin-bottom: 40px;
	}
}

.japfa-neeti-left img {
	width: calc(100% + 40px);
	height: auto;
	transform: translateX(40px);
}
@media (min-width: 991px) and (max-width: 1380px) {
	.japfa-neeti-left img {
		transform: translateX(130px);
	}
}

@media (max-width: 991px) {
	.japfa-neeti-left img {
		width: auto;
		max-width: 500px;
	}
}

@media (max-width: 600px) {
	.japfa-neeti-left img {
		width: calc(100% - 60px);
		max-width: 100%;
	}
}

.add-heading-icon.add-heading-icon-reimagine {
	position: relative;
}
.add-heading-icon.add-heading-icon-reimagine::before {
	position: absolute;
	top: -38%;
	left: -25px;
	content: url("../../../public/assets/img/icon-before-heading.svg");
}

@media (max-width: 768px) {
	.add-heading-icon.add-heading-icon-reimagine::before {
		left: -9%;
		top: -20px;
	}
}
@media (max-width: 368px) {
	.add-heading-icon.add-heading-icon-reimagine::before {
		left: 16%;
		top: -21px;
	}
}
