/* ================================================= GROW JAPFA LAYOUT =================================================   */
.grow-japfa-top-padding {
	padding: 100px 0px 100px;
}

@media (max-width: 475px) {
	.grow-japfa-top-padding {
		padding-bottom: 80px;
	}
}

.grow-japfa {
	background-color: var(--japfa-secondary);
	color: var(--japfa-white);
}

.grow-japfa .grow-japfa-title {
	font-size: 88px;
	font-weight: 700;
	line-height: normal;
}
@media (max-width: 1368px) {
	.grow-japfa .grow-japfa-title {
		font-size: 72px;
	}
}


.grow-japfa .grow-japfa-desc {
	font-size: 1.5rem;
	font-weight: 400;
	line-height: 36px;
	margin: 20px 0;
	position: relative;
	/* width: 1330px; */
}

.grow-japfa .grow-japfa-desc::after {
	content: "";
	position: absolute;
	left: 0;
	bottom: -40px;
	z-index: 1;
	width: 82%;
	height: 1px;
	background-color: var(--japfa-white);
}

@media (max-width: 1919px) {
	.grow-japfa .grow-japfa-desc::after {
		width: 100%;
	}
}

@media (max-width: 475px) {
	.grow-japfa .grow-japfa-desc::after {
		width: 94%;
		bottom: -30px;
	}
}

.grow-japfa-blocks {
	/* max-width: 1330px; */
	height: auto;
	display: flex;
	align-items: stretch;
	gap: 25px;
	margin-top: 84px;
}

@media (max-width: 991px) {
	.grow-japfa-top-padding {
		padding-top: 60px;
	}
}

@media (min-width: 1512px) and (max-width: 1919px) {
	.grow-japfa-top-padding {
		padding: 200px 178px 274px;
	}
}

@media (max-width: 1281px) {
  .grow-japfa .grow-japfa-title {
    font-size: 72px;
		/* font-weight: ; */
		/* padding: 0 24px; */
	}
}
@media (max-width: 769px) {
  .grow-japfa .grow-japfa-title {
    font-size: 40px;
    /* font-weight: ; */
    /* padding: 0 24px; */
  }
}

@media (max-width: 991px) {
	.grow-japfa .grow-japfa-desc {
		font-size: 14px;
		font-weight: 400;
		margin: 24px 0;
		/* padding: 0 24px; */
		line-height: 18px;
	}
}

@media (max-width: 768px) {
	.grow-japfa-blocks {
		flex-direction: column;
		align-items: center;
		margin-top: 80px;
		/* padding: 0 24px; */
	}
}

@media (max-width: 575px) {
	.grow-japfa-blocks {
		/* width: 392px; */
	}
}

.grow-japfa-blocks .grow-japfa-left {
	width: 47%;
	flex-grow: 1;
	max-width: 565px;
	height: auto;
	display: flex;
	flex-direction: column;
}

.grow-japfa-blocks .grow-japfa-right {
	width: calc(100% - 47%);
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 25px;
	color: var(--japfa-white);
}

/* @media (max-width: 1280px) {
	.grow-japfa-blocks .grow-japfa-right {
		width: calc(100% - 525px);
	}
}

@media (max-width: 1180px) {
	.grow-japfa-blocks .grow-japfa-right {
		width: calc(100% - 475px);
	}
}

@media (max-width: 991px) {
	.grow-japfa-blocks .grow-japfa-right {
		width: 100%;
	}
}

@media (max-width: 575px) {
	.grow-japfa-blocks .grow-japfa-right {
		width: 100%;
	}
} */

.grow-japfa-blocks .grow-japfa-right .grow-japfa-right-row {
	width: 100%;
	height: auto;
}

.grow-japfa-blocks .grow-japfa-right .grow-japfa-right-row:first-child,
.grow-japfa-blocks .grow-japfa-right .grow-japfa-right-row:last-child {
  /* height: 478px; */
	flex-grow: 1;
	background-color: var(--japfa-maroon);
	padding: 48px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.grow-japfa-blocks .grow-japfa-right .grow-japfa-right-row:last-child {
  flex-grow: unset; width: 100% !important;
}

@media (min-width: 1512px) and (max-width: 1919px) {
	/* .profile-row-footer {
		padding-bottom: 44px;
	} */
}

/* @media (max-width: 1180px) {
	.grow-japfa-blocks .grow-japfa-right .grow-japfa-right-row:first-child {
		height: 400px;
	}
} */

@media (max-width: 991px) {
	.grow-japfa-blocks .grow-japfa-right .grow-japfa-right-row:first-child {
		height: auto;
		/* min-height: 340px; */
		padding: 25px;
	}
}

.grow-japfa-blocks .grow-japfa-right .grow-japfa-right-row:last-child {
	/* height: calc(100% - 500px); */
	background-color: var(--japfa-chocolate);
	border: 1px solid rgba(255, 255, 255, 0.12);
	padding: 32px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

@media (max-width: 1180px) {
	.grow-japfa-blocks .grow-japfa-right .grow-japfa-right-row:last-child {
		/* height: calc(100% - 425px); */
	}
}

@media (max-width: 991px) {
	.grow-japfa-blocks .grow-japfa-right .grow-japfa-right-row:last-child {
		height: auto;
		/* min-height: 300px; */
		padding: 25px;
	}
}

@media (min-width: 1512px) and (max-width: 1919px) {
	.grow-japfa-blocks .grow-japfa-right .grow-japfa-right-row:last-child {
		width: 656px;
		/* height: calc(100% - 500px); */
		padding: 32px 32px 44px 32px;
	}
}

.grow-japfa-play {
	width: 100%;
	height: auto;
	background: linear-gradient(114deg, #ff845e 23.38%, #ff6332 100%);
	padding: 40px 32px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

@media (max-width: 1280px) {
	.grow-japfa-play {
		padding: 30px 20px;
	}
}

@media (max-width: 1024px) {
	.grow-japfa-play {
		padding: 25px 20px;
	}
}

.grow-japfa-play span {
	max-width: 245px;
	font-size: 40px;
	font-weight: 800;
	line-height: normal;
}

@media (max-width: 1024px) {
	.grow-japfa-play span {
		font-size: 30px;
	}
}

@media (max-width: 575px) {
	.grow-japfa-play span {
		font-size: 22px;
		max-width: 100%;
	}
}

.right-row-title {
	width: 100%;
	height: auto;
	font-size: 40px;
	font-weight: 400;
	line-height: normal;
}

@media (max-width: 768px) {
	.grow-japfa-blocks .grow-japfa-left,
	.grow-japfa-blocks .grow-japfa-right {
		width: 100% !important;
	}
}
@media (max-width: 991px) {
	.right-row-title {
		font-size: 24px !important;
	}
	.right-row-footer button {
		padding: 16px !important;
	}
}
@media (max-width: 1080px) {
	.right-row-title {
		font-size: 30px;
	}
}

@media (max-width: 475px) {
	.right-row-title {
		font-size: 24px;
		font-style: normal;
		font-weight: 400;
		line-height: 36px;
	}
}

.right-row-footer {
	width: 100%;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

@media (max-width: 575px) {
	.right-row-footer {
		flex-direction: column-reverse;
		align-items: flex-start;
		gap: 20px;
	}
}

.right-row-footer .right-row-footer-link {
	color: var(--japfa-primary);
	display: flex;
	align-items: center;
	gap: 20px;
	font-size: 20px;
	font-weight: 400;
	line-height: 32px;
	cursor: pointer;
}

.right-row-footer button {
	outline: none;
	box-shadow: none;
	padding: 20px 24px;
	border: 1px solid var(--japfa-white);
	color: var(--japfa-white);
	background-color: transparent;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	cursor: pointer;
}

.profile-row {
	width: 100%;
	height: auto;
	display: flex;
	align-items: flex-start;
	gap: 20px;
}

@media (max-width: 575px) {
	.profile-row {
		flex-direction: column;
	}
}

.profile-row img {
	width: 65px;
	height: auto;
}

.profile-row span {
	/* width: calc(100% - 85px); */
	height: auto;
	font-size: 24px;
	font-weight: 400;
	line-height: 36px;
}

@media (max-width: 1080px) {
	.profile-row span {
		font-size: 20px;
		line-height: 30px;
	}
}

.profile-row-footer {
	width: 100%;
	max-width: 530px;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	padding-top: 15px;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	margin-top: 20px;
}

@media (max-width: 575px) {
	.grow-japfa-container-fluid {
		padding: 0;
	}
}

.grow-japfa-img1 {
	border-bottom: 2px solid #fff;
	flex-grow: 1;
	object-fit: cover;
}
